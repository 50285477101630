*,
*:before,
*:after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.animated-border {
  height: 200px;
  width: 200px;
  transform: translate(52%,5%);
  z-index: 10;
  padding: 15px;
  border-radius: 20px;
//   box-shadow: 0 20px 35px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  
  &:before {
    content: "";
    height: 150%;
    width: 150%;
    position: absolute;
    background: linear-gradient(
        60deg,
        hsl(224, 85%, 66%),
        hsl(269, 85%, 66%),
        hsl(314, 85%, 66%),
        hsl(359, 85%, 66%),
        hsl(44, 85%, 66%),
        hsl(89, 85%, 66%),
        hsl(134, 85%, 66%),
        hsl(179, 85%, 66%)
      );
    left: -25%;
    top: -25%;
    animation: spin 5s infinite linear;
  }

  &:after {
    content: "";
    background-color: #1c1b29;
    height: 50%;
    width: 90%;
    border-radius: 5px;
    font-family: 'Poppins', sans-serif;
    color: #ffffff;
    display: grid;
    place-items: start;
  }
  @media only screen and (max-width: 1440px) {
     transform: translate(53%,5%);
     
    }
  @media only screen and (max-width: 1024px) {
   transform: translate(31%,5%);
   
  }
  @media only screen and (max-width: 768px) {
    transform: translate(31%,5%);
  
  }
  @media only screen and (max-width: 426px) {
    transform: translate(33%,5%);
  
  }
  @media only screen and (max-width: 322px) {
    transform: translate(27%,5%);
  
  }
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
