header,
footer {
  height: 100px;
  font-size: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #212121;
  color: #ffffff;
  padding-bottom: 23px;
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;

  border: 2px solid rgb(201, 201, 201) !important;
}
input:-webkit-autofill:focus {
  border: 2px solid #25b6ab !important;
}

.main-app {
  max-width: 440px;
  min-height: 800px;
  margin: auto;
  background-color: #ffffff;
  @media (max-width: 1024px) {
    max-width: 351px;
    width: 100%;
  }
}

@media (max-width: 575.99px) {
  .offer-page {
    min-width: 100% !important;
  }
}

// login styling
.login {
  &__title {
    @apply text-center font-[500] text-3xl;
  }
  &__subtitle {
    @apply text-center text-[11.4154px] text-[#6E7680] mt-3;
  }
}
// <======(login screen)======>
.login_screen {
  background-color: #fff;
  padding: 2rem 1rem;

  .site_input {
    @apply px-3 font-[400] text-secondary;
  }
}

// <======(My Account screen)======>
.my_account_screen {
  background-color: #fff;
  padding: 1rem;

  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10rem;

    & img {
      height: 45px;
      width: 57px;
      object-fit: cover;
      margin-top: 8px;
    }
  }

  .title {
    font-weight: 500;
    text-align: center;
    margin-bottom: 8px;
  }
  .sub_title {
    font-weight: 500;
    margin-bottom: 24px;
    text-align: center;
    color: #5f6368;
  }
  .list_item {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 8px;
    cursor: pointer;

    p {
      flex-grow: 1;
      margin: 4px;
      font-weight: 500;
      font-size: 16px;
      color: #5f6368;
    }

    & :hover {
      color: #25b6ab !important;
    }
  }
  .list_item:hover {
    background-color: aliceblue;
  }
  .link {
    text-decoration: none;
  }
}
// <======(My Account Details screen)======>
.my_details_screen {
  background-color: #fff;
  padding: 15px;

  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 11rem;

    .back-arrow {
      width: 28px;
      height: 28px;
      cursor: pointer;
    }
    & img {
      // height: 45px;
      // width: 57px;
      object-fit: cover;
      margin-top: 8px;
    }
  }
  .title {
    font-weight: 500;
    text-align: center;
    margin-bottom: 8px;
  }
  .sub_title {
    font-weight: 500;
    margin-bottom: 24px;
    text-align: center;
    padding: 0px 10px;
    color: #5f6368;
  }
  .site_input {
    @apply font-[400] text-[14px]  px-3;
  }
  .input_label {
    font-weight: 500;
    color: #6e7680;
    padding-left: 8px;
  }
  .checkbox-list {
    padding: 0 20px;
    margin-left: 20px;
    margin-bottom: 20px;
    li {
      display: flex;
      align-items: center;
      font-size: 12.5px;
      color: #5f6368;
      margin-bottom: 12px;

      input[type="checkbox"] {
        @apply mr-4 border-[#25B6AB] h-4 rounded-[2px] w-4;
        box-shadow: none;
        --checkbox-color: #25b6ab;
      }
      label {
        margin-bottom: 0;
      }
    }
  }
}

// <======(Brand Optin screen)======>
.brand-optin-screen {
  .checkbox-list {
    padding: 0;
    margin-left: 10px;
    margin-bottom: 20px;
    li {
      display: flex;
      align-items: start;
      font-size: 12.5px;
      color: black;
      margin-bottom: 12px;

      input[type="checkbox"] {
        margin-top: 4px;
        @apply mr-4 border-[#25B6AB] h-4 rounded-[2px] w-4;
        box-shadow: none;
        --checkbox-color: #25b6ab;
        height: 16px;
        width: 16px;
      }
      label {
        margin-bottom: 0;
        padding-top: 0;
      }
    }
  }
}

//signup styling
.signup {
  @apply shadow-xl py-[2rem] px-[1rem] bg-white;

  &__title {
    @apply font-[500]  text-center;
  }
  &__subtitle {
    @apply text-center mt-3 font-[500] text-[#6E7680] px-10;
  }
  &__signupForm {
    @apply mt-3;
  }

  &__links {
    @apply mb-4;
    &__privacylinks {
      @apply text-center   px-20 mt-4 text-secondary;
      & a {
        @apply text-primary focus:outline-none px-[3px] cursor-pointer underline decoration-1 underline-offset-2;
      }
    }
  }
}

// signup details page styling
.signupDetails {
  @apply shadow-xl p-[1rem] pt-[22px] pb-[22px] md:pb-[27px] md:pt-[27px] bg-white;
  &__title {
    @apply font-[500] text-center;
  }
  &__form {
    @apply mt-3;
  }

  &__tickbox {
    @apply flex my-2 gap-1 px-3 md:px-0  max-w-[355px] w-full mx-auto;
    &__checkbox {
      @apply font-light;
    }
  }
}

// verifyCode page styling
.verifyCode {
  @apply bg-white py-[2rem] px-[1rem] shadow-xl;
  &__title {
    @apply font-[500] text-center;
  }
  &__subtitle {
    @apply text-center mt-[22px] md:mt-[27px] text-[#6E7680] font-[500] px-10;
  }
  &__form {
    @apply my-0 my-[22px] md:my-[27px] relative;
    &__otp {
      @apply w-full flex justify-center;
    }
  }
  &__access {
    @apply mb-4 border-t-2 mt-16;
    &__data {
      @apply text-center  px-12  mt-16 text-secondary;
      & span {
        @apply text-primary underline decoration-1 underline-offset-2;
      }
    }
  }
  &__endbtn {
    @apply flex w-full;
    & button {
      @apply mt-3 text-primary font-[500] text-[14.36px] bg-white rounded-[40px] border border-primary w-full py-[16px] px-4;
    }
  }
}

// auth veriy screen styling
.Verify {
  @apply shadow-xl py-[2rem] px-[1rem] bg-white;
  &__title {
    @apply font-[500] text-center;
  }
  &__subtitle {
    @apply text-center mt-3 text-[#6E7680] px-10;
  }
  &__para {
    @apply mt-4 text-[#6E7680] text-center;
    & span {
      @apply text-primary;
    }
  }

  &__access {
    @apply mb-4 border-t-2 mt-16;
    &__para {
      @apply text-center  px-10 text-[11.42px]  mt-16 text-secondary;
      & span {
        @apply text-primary underline decoration-1 underline-offset-2 pl-1;
      }
    }
  }
}

// verified since page styling
.verirfiedSince {
  @apply flex flex-col main-app rounded-[18px] h-screen;
  &__parent {
    @apply flex justify-center h-full items-center;
  }
  &__child1 {
    @apply bg-white rounded-[18px] shadow-md  w-fit px-16 pb-10;
  }
  &__child2 {
    @apply flex justify-center items-center flex flex-col;
  }

  &__child3 {
    @apply h-[160px] w-[150px] md:h-36 md:w-36 flex justify-center bg-[#FF6B90] pt-4;
  }
  &__child4 {
    @apply h-[150px] w-[150px] md:h-36 md:w-36 bg-[#FF6B90] rounded-full p-2 absolute bottom-[-75px]  md:-bottom-20;
  }
  &__name {
    @apply text-center mt-36 md:mt-24 text-lightpurple;
    & h3 {
      @apply text-[26.12px] font-[700] text-lightpurple text-center;
    }
  }
}

.verifiedSince-main {
  height: 500px !important;
  min-height: auto !important;
  max-height: 800px !important;
}

// checkbox styling
input[type="checkbox"] {
  background-color: white;
  outline: none;
  color: var(--checkbox-color) !important;
  cursor: pointer;
}

input[type="checkbox"]::checked::before {
  background-color: var(--checkbox-color);
  outline: none !important;
}

input[type="checkbox"]:checked {
  background-color: var(--checkbox-color);
}

.grecaptcha-badge {
  visibility: hidden;
}
