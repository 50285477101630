// Login.scss

.select-auth {
  background-color: #fff;
  padding: 2rem 1rem;
  max-width: 440px;
  min-height: 200px;

  h2 {
    // font-size: 2rem;
    font-weight: 700;
    text-align: center;
  }
  .offer-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.75rem;

    img {
      margin-right: 0.5rem;
    }
  }
  .mb-3 {
    .link {
      text-decoration: none;
      width: 100%;
      height: 100%;
      display: block;
      color: #fff;
    }
  }

  .text-decoration {
    color: #6e7680;
    text-decoration: underline;
    cursor: pointer;
  }
}

.expires-img {
  position: absolute;
  left: 16px;
  bottom: -16px;
}

.page-title {
  margin-top: 16px;
  font-weight: 700;
  text-align: center;
  font-display: optional;
  font-size: 30px;

  @media (min-width: 767px) {
    font-size: 36px;
  }

  @media (min-width: 1024px) {
    font-size: 40px;
  }
}

.page-not-found-description {
  text-align: center;
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  margin-bottom: 22px;
  font-display: optional;

  @media (min-width: 768px) {
    font-size: 17px;
    padding: 0 10px;
  }

  @media (min-width: 1024px) {
    margin-bottom: 27px;
  }
}
