// Footer.scss

.footer {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  height: 100%;

  img {
    margin-top: 1rem;
    margin-bottom: 1rem;
    @apply lg:h-[65px] h-[57px] lg:w-[65px] w-[57px] my-3 mb-1;
  }
  .support-btn {
    @apply px-3  text-[#757575] bg-[#F4F5F7] rounded-[6.58974px] font-[500]  text-[11px] py-2;
  }
}
