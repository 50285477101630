.congratulations-page {
  background-color: #fff;
  padding: 2rem 1rem;
  .error_text {
    color: #e68387 !important;
  }
  .success_text {
    color: #25b6ab !important;
  }

  .my-btn {
    @apply border-[#D6D5D6] text-[#000] rounded-full hover:bg-transparent hover:text-[#000] transition-all duration-700;
  }

  .site-input {
    text-align: center;
    &::placeholder {
      text-align: center; // Center-align the placeholder text
      font-weight: 600;
    }
  }

  .hr-row {
    border: 1px solid #d6d5d6;
    border-radius: 4px;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .get-new-code {
    margin-bottom: 20px;
    font-weight: 500;
    text-decoration: underline;
    color: #6e7680;
    text-align: center;
    display: block;
  }

  p {
    color: #6e7680;
    text-align: center;
  }
}
