// Header.scss
.main-header {
  background-color: #fff;
  padding: 14px 14px 0 14px;
  height: 100%;

  .header {
    background: url("../../img/auth-images/hero-image.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    position: relative;
    border-radius: 12px;
    height: 150px;

    @media (max-width: 1024px) {
      height: 132px;
    }
    @media (max-width: 374px) {
      height: 126px;
    }

    // .back-link {
    //   position: absolute;
    //   margin-left: 1rem;
    //   margin-top: 1rem;
    //   font-size: 0.875rem;
    //   text-decoration: none;
    //   display: flex;
    //   align-items: center;
    //   gap: 4px;
    //   cursor: pointer;
    // }

    .image-container {
      position: absolute;
      // width: auto;
      width: 72px;
      height: 72px;
      // padding: 0.75rem;
      background-color: #fff;
      border-radius: 0.25rem;
      bottom: 16px;
      left: 16px;
      display: flex;
      justify-content: center;
      align-items: center;

      @media (max-width: 1024px) {
        // height: 72px;
        // width: 72px;
        // bottom: 24px;
      }
      @media (max-width: 767.99px) {
        bottom: 14px !important;
      }

      @media (max-width: 378px) {
        // height: 60px;
        // width: 60px;
      }
      @media (max-width: 344px) {
        // bottom: 30px;
      }
      @media (max-width: 302px) {
        // bottom: 36px;
      }
    }

    .verified-container {
      position: absolute;
      width: auto;
      padding: 0.5rem 0.75rem;
      background-color: #fff;
      border-radius: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      bottom: 16px;
      right: 16px;
      gap: 8px;
      @media (max-width: 1024px) {
        bottom: 24px;
      }
      @media (max-width: 344px) {
        bottom: 30px;
      }
      @media (max-width: 302px) {
        bottom: 36px;
      }
    }

    .verified-container p {
      margin-bottom: 0;
      color: #0000;
      font-size: 14px;
      font-weight: 500;
      @media (max-width: 1024px) {
        font-size: 11px;
      }
    }
  }
}
