.file-selector {
  max-width: 460px;
  width: 100%;
  // height: 185px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @apply h-[215px] md:h-[230px] relative;
  justify-content: center;
  background: #e4fffd;
  border: 0.815385px dashed #25b6ab;
  border-radius: 3.26154px;

  .file-selector-box {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    @apply w-[100%] lg:w-[80%] relative;
    justify-content: center;
    height: 70%;

    p {
      color: #676767;
      text-align: center;
      margin-top: 10px;
    }

    .file-selector-heading {
      font-size: 16px;
      font-weight: bold;
      text-align: center;
      cursor: pointer;
    }

    .file-selector-popup {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      background-color: #e1e1e1;
      // border: 1px solid #ccc;
      width: 100%;
      z-index: 9;
      border-radius: 13.4615px;
      overflow: hidden;

      label {
        color: black;
        width: 100%;
        text-align: left;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        margin-bottom: 0;
        cursor: pointer;
      }
      input {
        display: none;
      }
    }
  }
}
.bordergray {
  border-bottom: 1px solid #c7c7c7;
}

.selected-file-details-container {
  position: relative;
  max-width: 460px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;

  .number-of-files {
    color: #676767;
    margin-bottom: 0;
  }

  .selected-file-details {
    width: 100%;
    display: flex;
    background-color: #ffffff;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    border: 0.448718px solid #e3e3e3;
    border-radius: 3.58974px;

    .svg-icon {
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      background-color: #ccc;
      border-radius: 50%;
      padding: 4px;

      &:hover {
        background-color: #c4c2c2;
      }
    }
  }
  .selected-file-details-success {
    width: 100%;
    display: flex;
    background-color: #ffffff;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    border: 0.448718px solid #11af22;
    border-radius: 3.58974px;

    .svg-icon {
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      background-color: #ccc;
      border-radius: 50%;
      padding: 4px;

      &:hover {
        background-color: #c4c2c2;
      }
    }
  }
  .progress {
    color: #25b6ab;
    height: 3px;
    position: absolute;
    width: 100%;
    bottom: 0;
  }
}
